"use client";
import * as React from "react";
import { AppProvider } from "@shopify/polaris";
import enTranslations from "@shopify/polaris/locales/en.json";
import { LinkLikeComponentProps } from "@shopify/polaris/build/ts/src/utilities/link";
import Link from "next/link";

function LinkWrapper(props: LinkLikeComponentProps) {
  const { children, url, ref, ...rest } = props;

  return (
    <Link href={url} ref={ref as any} {...rest}>
      {children}
    </Link>
  );
}

export default function ThemeRegistry({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <AppProvider i18n={enTranslations} linkComponent={LinkWrapper}>
      {children}
    </AppProvider>
  );
}
