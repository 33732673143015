import { useEnvironmentBrandQuery } from "@/__generated__/graph202310/useEnvironmentBrandQuery.graphql";
import { useAuth, useOrganization } from "@clerk/nextjs";
import { useParams } from "next/navigation";
import { useState, useEffect } from "react";
import { PreloadedQuery, loadQuery } from "react-relay";
import {
  Network,
  Store,
  RecordSource,
  Environment,
  graphql,
  Observable,
  RequestParameters,
  Variables,
  SubscribeFunction,
  CacheConfig,
  GraphQLResponse,
} from "relay-runtime";

import { createClient } from "graphql-ws";
import { LegacyObserver } from "relay-runtime/lib/network/RelayNetworkTypes";
import { devOverrideApiEndpoint } from "@/app/constants";

function defaultFetchFunction(params: any, variables: any) {
  const response = fetch(devOverrideApiEndpoint + "/graphql", {
    method: "POST",
    headers: [["Content-Type", "application/json"]],
    body: JSON.stringify({
      query: params.text,
      variables,
    }),
  });

  return Observable.from(response.then((data) => data.json()));
}

/**
 * Custom fetch function to handle GraphQL requests for a Relay environment.
 *
 * This function is responsible for sending GraphQL requests over the network and returning
 * the response data. It can be customized to integrate with different network libraries or
 * to add authentication headers as needed.
 *
 * @param {RequestParameters} params - The GraphQL request parameters to send to the server.
 * @param {Variables} variables - Variables used in the GraphQL query.
 */
const fetchFunction =
  (session: ReturnType<typeof useAuth>, orgId: string, apiEndpoint: string) =>
  async (params: any, variables: any) => {
    const token = await session.getToken();
    if (!token) {
      throw new Error("token was not generated");
    }

    if (!orgId) {
      throw new Error("missing orgId");
    }

    const apiVersion = "2023-10";
    const gqlEndpoint = `${apiEndpoint}/admin/api/${apiVersion}/graphql`;

    const headers = {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
      "x-org-id": orgId,
    };

    const apqBody = {
      extensions: {
        persistedQuery: {
          version: 1,
          sha256Hash: params.id,
        },
      },
    };

    const queryBody = { query: params.text };

    const runFetch = async (body: Record<string, any>) => {
      const response = await fetch(gqlEndpoint, {
        method: "POST",
        headers,

        body: JSON.stringify(body),
      });

      if (response.status !== 200) {
        throw new Error("returned status was unexpected");
      }

      return await response.json();
    };

    if (params.id) {
      const resp = await runFetch({ ...apqBody, variables });
      if (resp.data) {
        return resp;
      }

      const foundNotPersisted = resp?.errors?.find(
        (err: any) => err.message === "PersistedQueryNotFound"
      );

      if (foundNotPersisted) {
        console.log("submitting query");
        const queriesResp = await fetch("/queries.json");
        const queries = await queriesResp.json();
        console.log("find query", params.id, queries[params.id]);
        if (queries[params.id]) {
          return await runFetch({
            query: queries[params.id],
            ...apqBody,
            variables,
          });
        }

        return resp;
      }

      return resp;
    }

    return await runFetch({ queryBody, variables });
  };

const subscribeFunction = (
  session: ReturnType<typeof useAuth>,
  orgId: string,
  apiEndpoint: string
): SubscribeFunction => {
  const apiVersion = "2023-10";
  const gqlEndpoint = `${apiEndpoint}/admin/api/${apiVersion}/graphql`;

  const subscriptionsClient = createClient({
    url: gqlEndpoint
      .replace("https://", "wss://")
      .replace("http://", "ws://")
      .replace("/graphql", "/ws"),
    retryAttempts: 100,
    retryWait: (nRetries: number): Promise<void> => {
      console.log("retrying", nRetries);
      return new Promise((resolv) => {
        setTimeout(resolv, 100 * nRetries);
      });
    },
    shouldRetry: (errOrClose) => {
      console.log(errOrClose);
      return true;
    },
    connectionParams: async () => {
      const token = await session.getToken();

      return {
        Authorization: `Bearer ${token}`,
        "x-org-id": orgId,
      };
    },
  });

  return (
    request: RequestParameters,
    variables: Variables,
    cacheConfig: CacheConfig,
    observer?: LegacyObserver<GraphQLResponse>
  ) => {
    return Observable.create((sink: any) => {
      return subscriptionsClient.subscribe(
        {
          operationName: request.name,
          query: "",
          variables,
          extensions: {
            persistedQuery: {
              version: 1,
              sha256Hash: request.id,
            },
          },
        },
        sink
      );
    });
  };
};

const rs = new RecordSource();
const store = new Store(rs);

export const createModalBrandQuery = graphql`
  query useEnvironmentBrandQuery {
    brands {
      nodes {
        id
        name
      }
    }
  }
`;

const useEnvironment = () => {
  const session = useAuth();
  const params = useParams<{ storeId: string }>();
  const [ready, setReady] = useState(false);
  const [environment, setEnvironment] = useState<Environment>(
    new Environment({ store, network: Network.create(defaultFetchFunction) })
  );
  const org = useOrganization();
  const apiEndpoint =
    devOverrideApiEndpoint ||
    (org.organization?.publicMetadata["apiEndpoint"] as string);

  useEffect(() => {
    if (!session.isLoaded || !params.storeId || !apiEndpoint) {
      setReady(false);
      return;
    }

    const network = Network.create(
      fetchFunction(session, params.storeId, apiEndpoint),
      subscribeFunction(session, params.storeId as string, apiEndpoint)
    );

    rs.clear(); // clear all of the cached data
    const env = new Environment({ store, network });
    setEnvironment(env);

    setReady(true);
  }, [params.storeId, session.orgId, session.isLoaded, apiEndpoint]);

  return { environment, ready, apiEndpoint };
};

export default useEnvironment;
