/**
 * @generated SignedSource<<406980eaee075e0d8f0b3d0f3feba8e4>>
 * @relayHash 0ebc1296e6347d645f820babc883a5d5
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 1fdd341320b3fafc4385fdce02485924d3c2087ae2b923f50e1fbd38b9d9d7fb

import { ConcreteRequest } from 'relay-runtime';
export type useEnvironmentBrandQuery$variables = Record<PropertyKey, never>;
export type useEnvironmentBrandQuery$data = {
  readonly brands: {
    readonly nodes: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
  };
};
export type useEnvironmentBrandQuery = {
  response: useEnvironmentBrandQuery$data;
  variables: useEnvironmentBrandQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "BrandConnection",
    "kind": "LinkedField",
    "name": "brands",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Brand",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useEnvironmentBrandQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useEnvironmentBrandQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "1fdd341320b3fafc4385fdce02485924d3c2087ae2b923f50e1fbd38b9d9d7fb",
    "metadata": {},
    "name": "useEnvironmentBrandQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "4950a68be2ba75c5c68ec5c491e91d37";

export default node;
