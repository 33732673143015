"use client";
import ThemeRegistry from "@/components/ThemeRegistry/ThemeRegistry";
import DataLayerProvider from "@/components/DataLayerProvider";
import "@shopify/polaris/build/esm/styles.css";
import "./globals.scss";
import { ClerkProvider } from "@clerk/nextjs";
import React from "react";
import ErrorBoundary from "@/components/ErrorBoundary";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { usePathname } from "next/navigation";
// const inter = Inter({ subsets: ["latin"] });

// export const metadata: Metadata = {
//   title: "Create Next App",
//   description: "Generated by create next app",
// };

type IProps = {
  children: React.ReactNode;
};

export default function RootLayout(props: IProps) {
  const pathname = usePathname();
  const signInUrl = pathname.includes("/apps/shopify/auth")
    ? pathname
    : "/auth/sign-in";
  const signUpUrl = pathname.includes("/apps/shopify/auth")
    ? pathname
    : "/auth/sign-up";

  return (
    <html lang="en">
      <head>
        <link rel="icon" href="/icon.png" sizes="any" />
        <title>Glide</title>
      </head>
      <body className="Glide">
        <ErrorBoundary>
          <ThemeRegistry>
            <ClerkProvider
              publishableKey={process.env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY}
              signInUrl={signInUrl}
              signUpUrl={signUpUrl}
              signInFallbackRedirectUrl="/auth/signed-in"
              signUpFallbackRedirectUrl="/auth/signed-up"
            >
              <DataLayerProvider>{props.children}</DataLayerProvider>
            </ClerkProvider>
          </ThemeRegistry>
          <ToastContainer position="bottom-right" />
        </ErrorBoundary>
      </body>
    </html>
  );
}
