"use client";
import useEnvironment from "@/hooks/useEnvironment";
import Bugsnag from "@bugsnag/js";
import { useOrganization, useUser } from "@clerk/nextjs";
import React, { createContext, useContext, useEffect } from "react";
import { RelayEnvironmentProvider } from "react-relay";

export const EnvironmentContext = createContext({
  ready: false,
  apiEndpoint: "",
});

export default function DataLayerProvider(props: any) {
  const { environment, ready, apiEndpoint } = useEnvironment();
  const user = useUser();
  const org = useOrganization();

  useEffect(() => {
    if (!user.isSignedIn) {
      return;
    }

    Bugsnag.setUser(
      user.user?.id,
      user.user?.primaryEmailAddress?.emailAddress,
      user.user?.fullName || undefined
    );
    Bugsnag.addMetadata("org", {
      apiEndpoint,
      orgId: org.organization?.id,
      orgName: org.organization?.name,
    });
  }, [
    user.isSignedIn,
    user.user?.id,
    user.user?.primaryEmailAddress,
    user.user?.fullName,
    apiEndpoint,
    org.organization,
  ]);

  return (
    <RelayEnvironmentProvider environment={environment}>
      <EnvironmentContext.Provider value={{ ready, apiEndpoint }}>
        {props.children}
      </EnvironmentContext.Provider>
    </RelayEnvironmentProvider>
  );
}
