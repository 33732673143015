"use client";
import Bugsnag from "@bugsnag/js";
import React from "react";
import { BlockStack, EmptyState, Spinner, Text } from "@shopify/polaris";
import BugsnagPluginReact from "@bugsnag/plugin-react";

Bugsnag.start({
  apiKey: "a4b08e586d6c92d7bc613933966a80c8",
  enabledReleaseStages: ["live", "staging", "production"],
  releaseStage:
    process.env.NEXT_PUBLIC_RELEASE_STAGE || process.env.RELEASE_STAGE || "dev",
  plugins: [new BugsnagPluginReact()],
  autoTrackSessions: false,
  appVersion: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA?.substring(0, 7),
});

const ErrorBoundary = Bugsnag.getPlugin("react")!.createErrorBoundary(React);

const ErrorView = () => (
  <EmptyState image="/images/svg/glide-logo.svg">
    <BlockStack gap="400">
      <div style={{ marginTop: 20 }} />
      <Text variant="headingLg" as="h1">
        Unexpected Error
      </Text>
      <p>
        It looks like you&apos;ve found a rather bad problem. We&apos;ll have
        received a notifcation about it.
      </p>
      <p>Your options:</p>
      <ol>
        <li>Try again immediately to confirm if it&apos;s temporary or not</li>
        <li>Try again in a few minutes to see if it&apos;s resolved itself</li>
        <li>
          Send us a message support@glidenow.com with some details of what you
          were trying to do
        </li>
      </ol>
    </BlockStack>
  </EmptyState>
);

export default function Boundary(props: { children: any }) {
  if (process.env.NODE_ENV !== "production") {
    return props.children;
  }

  if (!ErrorBoundary) {
    return props.children;
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorView}>
      {props.children}
    </ErrorBoundary>
  );
}
